.root {
  display: grid;
  grid-template-columns:
    [vf-start]
    1fr
    [device-start]
    auto
    [device-end]
    24px
    [vf-end];
  grid-template-rows:
    [vf-start]
    1fr
    [device-start]
    auto
    [device-end]
    24px
    [vf-end];
}

.viewFinder {
  grid-area: vf;
  align-self: stretch;
  display: flex;
}

.viewFinder video {
  object-fit: cover;
}

.deviceSelector {
  grid-area: device;
}
