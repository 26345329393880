.root,
.root form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.root {
  overflow: hidden;
  padding: 2rem;
  min-width: 305px;
}
