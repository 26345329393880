.root {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--peripheral-section-bg-color);
  padding: 0.5rem 1rem;
  width: 100vw;
  height: var(--height-footer);
}

.root svg {
  color: #fff;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.inactive {
  opacity: 0.3;
}
