.root {
  display: grid;
  grid-template-columns:
    [input-start]
    minmax(24px, 1fr)
    [empty-start]
    auto
    [empty-end]
    minmax(24px, 1fr)
    [input-end];
  grid-template-rows:
    1fr
    [input-start empty-start]
    auto
    [input-end empty-end]
    1fr;
}

.input {
  grid-area: input;
  font-size: 10vmin;
  font-family: monospace;
  padding: 0;
  background: none;
  border: none;
  text-align: center;
  width: auto;
  min-width: 0;
}

.input:focus {
  outline: none;
}

.input.hidden {
  opacity: 0;
  pointer-events: none;
}

.empty {
  grid-area: empty;
  max-width: 50ch;
}

.laser {
  margin-top: 16px;
  margin-bottom: 24px;
}
