.root {
  background-color: #000c;
  color: var(--success-failure-text-color);
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  text-align: center;
  cursor: pointer;
  padding: 0 1rem;
}

.infoSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}

.visualSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.success {
  background-color: var(--success-color);
}
.failure {
  background-color: var(--failure-color);
}

.headline {
  text-wrap: balance;
}

.ticketInfo {
  margin: 2rem 0;
}

.horizontalLine {
  margin: 1rem 0;
  width: 85dvw;
  border: 1px solid currentColor;
  opacity: 0.5;
}

/** dynamic success background colors - can be chosen in the event-backend */
.success-blue {
  background-color: var(--success-background-color-blue);
}
.success-brown {
  background-color: var(--success-background-color-brown);
}
.success-orange {
  background-color: var(--success-background-color-orange);
  color: var(--success-bright-background-text-color);
}
.success-pink {
  background-color: var(--success-background-color-pink);
}
.success-purple {
  background-color: var(--success-background-color-purple);
}
.success-yellow {
  background-color: var(--success-background-color-yellow);
  color: var(--success-bright-background-text-color);
}
