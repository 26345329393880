.ticketContainer {
  display: flex;
  flex-direction: column;
  width: 255px;
  color: var(--color-fatal-error-info);
  margin: auto;
  text-align: center;
  background-color: var(--background-fatal-error-info);
  border-radius: var(--border-radius-fatal-error-info);
}

.topContainer {
  display: flex;
  flex-direction: column;
  background-color: var(--background-fatal-error-info);
  border-top-right-radius: var(--border-radius-fatal-error-info);
  border-top-left-radius: var(--border-radius-fatal-error-info);
}

.headline {
  font-weight: bold;
  padding-top: 18px;
}

.info {
  margin-top: 18px;
}

.horizontalLine {
  margin: 1rem 0;
  background-color: var(--success-failure-text-color);
  opacity: 0.5;
}

.bottomContainer {
  background-color: var(--background-fatal-error-info);
  border-bottom-right-radius: var(--border-radius-fatal-error-info);
  border-bottom-left-radius: var(--border-radius-fatal-error-info);
  margin-bottom: 20px;
}

.ripLine {
  background-color: var(--background-fatal-error-info);
  position: relative;
  border: 1px dashed #aaa;
  margin: 20px 10px;
}

.ripLine::before,
.ripLine::after {
  content: '';
  position: absolute;
  width: 30px;
  height: 30px;
  top: 50%;
  background-color: #000;
  border-radius: 50%;
}
.ripLine::before {
  left: -25px;
  top: -15px;
}
.ripLine::after {
  transform: translate(-50%, -50%) rotate(225deg);
  right: -40px;
}
