.root {
  height: 100%;
  width: 100%;
}

.rootWithoutHeader {
  display: grid;
  grid-template-rows:
    [content-start]
    1fr
    [content-end status-start]
    auto
    [status-end];
}

.rootWitHeader {
  display: grid;
  grid-template-rows:
    [header-start]
    auto
    [header-end content-start]
    1fr
    [content-end status-start]
    auto
    [status-end];
}

.header {
  grid-area: header;
}

.content {
  grid-area: content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 0;
}

.status {
  grid-area: status;
}

.fullWidthAndHeight {
  height: 100%;
  width: 100%;
}
