.root {
  display: grid;
  grid-template-columns:
    [input-start result-start]
    1fr
    [history-button-start]
    auto
    [history-button-end]
    8px
    [picker-start]
    auto
    [picker-end]
    8px
    [input-end result-end];
  grid-template-rows:
    [input-start result-start]
    8px
    [picker-start history-button-start]
    auto
    [history-button-end picker-end]
    1fr
    [input-end result-end];
}

.input {
  grid-area: input;
  z-index: 10;
}

.picker {
  grid-area: picker;
  z-index: 20;
}

.checkinHistoryButton {
  grid-area: history-button;
  z-index: 20;
}

.result {
  grid-area: result;
  z-index: 30;
}
