.success {
  background-color: rgba(0, 128, 0, 0.2);
}

.error {
  background-color: rgba(255, 0, 0, 0.2);
}

.icon {
  margin-left: 12px;
}
