@keyframes pulsate {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

.laser {
  --overshoot: 10%;
  height: 1px;
  background: linear-gradient(to right, #f000, #f00f var(--overshoot), #f000 100%);
  margin-left: calc(var(--overshoot) * -1);
  animation: pulsate 5s infinite;
}
